import React, { useEffect } from 'react';import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Logo from './components/Logo';
import TextBlock from './components/TextBlock';
import ScrollTriggeredBox from './components/ScrollTriggeredBox';
import ImageMosaic from './components/ImageMosaic';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './components/Navbar';


function AnotherPage() {
    return <h1>Welcome to another page!</h1>;
}

function App() {

  const images = [
    '/Mosaic/Church.jpg',
    '/Mosaic/Kids_gifts.jpg',
    '/Mosaic/CulturalCenter.jpg', 
    '/Mosaic/Bingo.jpg',   
    '/Mosaic/Henry.jpg', 
    '/Mosaic/Park.jpg',
    '/Mosaic/Corner.jpg',
    '/Mosaic/Kids_shoes.jpg', 
    '/Mosaic/Kids_uniform.jpeg',
    '/Mosaic/Bust.jpg',
    '/Mosaic/Kids.jpg',
    // ... add more image paths

  ];

  return (
    <Router>
      <div className='App'>
       <header className="App-header">
        <Logo />
       </header>
      <Navbar />
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* Add other routes as needed */}
      </Routes>
        <div className='image-container'>
          <ImageMosaic images={images} />
        </div>
      </div>
    </Router>
  );
}

export default App;
