import React from 'react';

function ImageMosaic({ images }) {
  return (
    <div className="mosaic">
      {images.map((src, index) => (
        <img key={index} src={src} alt={`Mosaic ${index}`} className={`mosaic-image mosaic-image-${index}`} />
      ))}
    </div>
  );
}

export default ImageMosaic;
