// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav>
        <div className='nav-item'>
            <Link to="/about">About</Link>
        </div>
        <div className='nav-item'>
            <Link to="/contact">Contact</Link>
        </div>
      {/* Add other navigation links as needed */}
    </nav>
  );
}

export default Navbar;
